import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RouterStateService } from '../../services/route-state/route-state.service';
import {
  setSelectedTab,
  setSelectedVasType,
} from '../../shared/store-utilities/actions/payment.action';
import { take } from 'rxjs/operators';
import {
  Modules,
  Payments,
} from '../../core/masking-service/masking-constants';
import { MaskingService } from '../../core/masking-service/masking.service';
import { DataSharingService } from '../../core/data-sharing/data-sharing.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'sbg-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss'],
})
export class PaymentsComponent implements OnInit {
  selectedTab: number;
  isListPage = false;
  isIpayFAQ = false;
  screenWith: string;
  mobileView = false;
  dividerPresent = false;
  faqs = false;
  selectedSubTab: number;
  isOpenBillSubMenu = false;
  listOfApplicableVasPayment: Array<any>;
  listOfShowingPayments: Array<any>;
  config = environment.config;
  isSmeMode: boolean;
  listOfSubBillPayments = [
    {
      feature: Payments.PAY_ELECTRICITY,
      name: 'Electricity',
      type: 'ELECTRICITY',
    },
    {
      feature: Payments.PAY_RATES_AND_TAXES,
      name: 'Rates And Taxes',
      type: 'RATESANDTAXES',
    },
    {
      feature: Payments.PAY_WATER,
      name:
        this.config.countryName === 'Tanzania'
          ? 'Government payments (GePG)'
          : 'Water',
      type: 'WATER',
    },
    {
      feature: Payments.BILLER_PAYMENTS,
      name: 'Biller',
      type: 'BILLER',
    },
    {
      feature: Payments.PAY_GOODS_AND_SERVICES,
      name: 'Goods and Services',
      type: 'GOODSANDSERVICES',
    },
    {
      feature: Payments.GEPG,
      name: 'GEPG',
      type: 'GEPG',
    },
  ];

  listOfPayments = [
    {
      feature: Payments.BENEFICIARY_PAYMENTS,
      name: 'Beneficiaries',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/beneficiary/list'),
    },
    {
      feature: Payments.UNAYO_PAYMENTS,
      name: 'Unayo Payment',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/unayo/list'),
    },
    {
      feature: Payments.BILLER_PAYMENTS,
      name: 'Bill Payments',
      onClickNavFunction: (index) => this.goToBillPayments(index),
    },
    {
      feature: Payments.MOBILE_WALLET,
      name: this.config.isBillerCDI
        ? 'Pay Pulse'
        : this.config.countryName === 'Ghana'
        ? 'Slydepay Wallet'
        : 'Mobile Wallet',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/MobileWallet/details'),
    },
    {
      feature: Payments.CMA_BENEFICIARY_PAYMENTS,
      name: 'CMA Beneficiaries',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/cma/list'),
    },
    {
      feature: Payments.INSTANT_PAYMENTS,
      name: 'Instant Payment',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/gip/list'),
      belowLine: true,
    },
    {
      feature: Payments.TAX_PAYMENT,
      name: 'Tax payment',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/taxPayment/details'),
      belowLine: true,
    },
    {
      feature: Payments.GHANA_QR,
      name: 'GHQR',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/qr/once-off'),
      belowLine: true,
    },
    {
      feature: Payments.NAMIBIA_TAX_PAYMENT,
      name: 'Tax Payment',
      onClickNavFunction: (index) =>
        this.goToTargetModule(
          index,
          this.isSmeMode
            ? './payments/na-tax-payment/list'
            : './payments/na-tax-payment/details'
        ),
      belowLine: true,
    },
    {
      feature: Payments.ACCOUNT_TRANSFERS,
      name: 'Self Account Transfer',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/selfFund/details'),
      belowLine: true,
    },
    {
      feature: Payments.BULK_FILE_UPLOAD,
      name: 'Bulk File Upload',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/bulkFileUpload/list'),
      belowLine: true,
    },
    {
      feature: Payments.INTERNATIONAL_PAYMENTS,
      name: 'International Payment',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/internationalPayment/onceOff'),
      belowLine: true,
    },
    {
      feature: Payments.SCHOOLFEES,
      name: 'Fees Payment',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/schoolfees/list'),
      belowLine: true,
    },
    {
      feature: Payments.CROSS_BORDER_PAYMENTS,
      name: 'Cross Border Payment',
      onClickNavFunction: (index) =>
        this.goToTargetModule(index, './payments/crossBorderPayment/list'),
      belowLine: true,
    },
  ];

  private readonly BILLER = 'biller';
  private readonly VAS = 'vas';
  private readonly VAS_RECEIPT_FLOW = 'vas/receipt';
  private readonly BILLER_MANAGEMENT_FLOW = 'BILLER_MANAGEMENT';

  constructor(
    private router: Router,
    private maskingService: MaskingService,
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
    private routeState: RouterStateService,
    private dataSharingService: DataSharingService
  ) {
    window['inner']('transaction');
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.indexOf('list') >= 0) {
          this.isListPage = true;
        } else {
          this.isListPage = event.url.indexOf('onceOff') >= 0;
        }
        if (event.url.indexOf('paymentfaqs') >= 0) {
          this.faqs = true;
        } else {
          this.faqs = false;
        }
        if (event.url.indexOf('ipayFAQ') >= 0) {
          this.isIpayFAQ = true;
        } else {
          this.isIpayFAQ = false;
        }
      }
    });
    window['inner']('transaction');
    this.store.select('appReducer', 'loginReducer').subscribe((stateData) => {
      this.isSmeMode = stateData.isSmeMode;
    });
  }

  ngOnInit() {
    this.prepareListOfAvailablePayment();

    this.store
      .select('appReducer', 'selectedPaymentsTabReducer')
      .subscribe((data) => {
        this.selectedTab = data.selectedTab || 0;
      });

    this.updateMenuAfterVasPayment();
    this.updateMenuAfterBillerManagement();
  }

  goToBillPayments(index) {
    this.selectedTab = index;
    this.checkAndUpdateSubMenuFlag();
    this.store.dispatch(setSelectedTab({ selectedTab: index }));
    if (!this.isOpenBillSubMenu) {
      return this.router.navigate(['./payments/biller/list']);
    } else {
      this.subFeatureClicked(0);
    }
  }

  subFeatureClicked(index) {
    this.selectedSubTab = index;
    const type = this.listOfApplicableVasPayment[index];
    if (Payments.GEPG === type.feature) {
      this.router.navigate(['./payments/gepg/list']);
    } else if (Payments.BILLER_PAYMENTS === type.feature) {
      return this.router.navigate(['./payments/biller/list']);
    } else {
      this.store.dispatch(
        setSelectedVasType({
          selectedVasType: {
            type: type.type,
            description: type.name,
            subIndexTab: index,
          },
        })
      );
      return this.router
        .navigateByUrl('/payments/vas', { skipLocationChange: true })
        .then(() => {
          this.router.navigate(['/payments/vas/list'], {
            relativeTo: this.activatedRoute,
          });
        });
    }
  }

  goToTargetModule(index: number, routeUrl: string) {
    this.selectedTab = index;
    this.isOpenBillSubMenu =
      !routeUrl.includes(this.BILLER) || !routeUrl.includes(this.VAS)
        ? false
        : this.isOpenBillSubMenu;
    this.store.dispatch(
      setSelectedTab({
        selectedTab: !(
          routeUrl.includes('details') || routeUrl.includes('qr/once-off')
        )
          ? index
          : 0,
      })
    );
    return this.router.navigate([routeUrl]);
  }

  checkIfDividerIsNeeded(listItem) {
    if (!this.dividerPresent && listItem.belowLine) {
      this.dividerPresent = true;
      return true;
    }
    return false;
  }

  private updateMenuAfterVasPayment() {
    const currentUrl = this.routeState.getCurrentUrl();
    if (currentUrl.includes(this.VAS_RECEIPT_FLOW)) {
      this.store
        .select('appReducer', 'selectedVasTypeReducer')
        .pipe(take(1))
        .subscribe((data) => {
          if (data.selectedVasType && data.selectedVasType.subIndexTab >= 0) {
            this.selectedSubTab = data.selectedVasType.subIndexTab;
            this.isOpenBillSubMenu = true;
          }
        });
    }
  }

  private updateMenuAfterBillerManagement() {
    if (this.BILLER_MANAGEMENT_FLOW === this.dataSharingService.currentFlow) {
      if (this.listOfApplicableVasPayment.length > 0) {
        this.selectedSubTab = this.listOfApplicableVasPayment.findIndex(
          (item) => item.type === this.BILLER.toUpperCase()
        );
        this.isOpenBillSubMenu = true;
      }
    }
  }

  private prepareListOfAvailablePayment() {
    this.listOfApplicableVasPayment = this.maskingService.removeMaskedFeatures(
      this.listOfSubBillPayments,
      Modules.PAYMENTS
    );
    if (this.listOfApplicableVasPayment.length === 1) {
      this.listOfPayments.find(
        (item) => item.feature === Payments.BILLER_PAYMENTS
      ).name =
        this.listOfApplicableVasPayment[0].feature === Payments.BILLER_PAYMENTS
          ? this.config.isBillerCDI
            ? 'Companies'
            : 'Billers'
          : `Pay ${this.listOfApplicableVasPayment[0].name}`;
    }
    // Prevent removal of biller payments menu item in case of vas payments presence
    if (this.listOfApplicableVasPayment.length) {
      this.listOfPayments.find(
        (item) => item.feature === Payments.BILLER_PAYMENTS
      ).feature = undefined;
    }
    this.listOfShowingPayments = this.maskingService.removeMaskedFeatures(
      this.listOfPayments,
      Modules.PAYMENTS
    );
  }

  private checkAndUpdateSubMenuFlag() {
    if (
      this.listOfApplicableVasPayment
        .map((item) => item.feature)
        .includes(Payments.BILLER_PAYMENTS)
    ) {
      if (this.listOfApplicableVasPayment.length - 1) {
        this.isOpenBillSubMenu = true;
      }
    } else {
      if (this.listOfApplicableVasPayment.length) {
        this.isOpenBillSubMenu = true;
      }
    }
  }
}
