import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';


import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { environment } from '@environment';

@Component({
  selector: 'app-statement-day-range-filter',
  templateUrl: './statement-day-range-filter.component.html',
  styleUrls: ['./statement-day-range-filter.component.scss'],
})
export class StatementDayRangeFilterComponent implements OnInit, OnDestroy {
  @Output() statementDateRangeValues: EventEmitter<any> = new EventEmitter();

  subscription: Subscription;
  showCycleRange = false;
  config = environment.config;

  statmentDaysList = [
    { label: '30 Days', value: 30, term: 'day', isDisabled: false },
    { label: '60 Days', value: 60, term: 'day', isDisabled: false },
    { label: '90 Days', value: 90, term: 'day', isDisabled: false },
    { label: '180 Days', value: 180, term: 'day', isDisabled: false },
  ];

  statmentCycleList = [
    { label: 'Current Month', value: 'CURRENT_MONTH', isDisabled: true },
    { label: 'Last Month', value: 'LAST_MONTH', isDisabled: false },
    { label: '2 Months Ago', value: 'TWO_MONTHS_AGO', isDisabled: false }
  ];

  constructor( private store: Store<{ appReducer }> ) {}

  ngOnInit() {
    this.subscription = this.store.select('appReducer', 'dashboardReducer').subscribe((value) => {
      if ( value && value.selectedAccount && value.selectedAccount.accountType === 'CREDIT_CARD' ) {
        this.showCycleRange = true;
      }
    });
  }

  getPastStatements(val: any, isDisabled: boolean) {
    if (isDisabled) {
      return true;
    } else {
      _.forEach(this.statmentDaysList, (itm) => {
        itm.isDisabled = itm.value <= val.value ? true : false;
      });
    }

    this.statementDateRangeValues.emit(val.value);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getCycleStatements(val: any, isDisabled: boolean) {
      _.forEach(this.statmentCycleList, (itm) => {
        if ( itm.value === val.value ) {
          itm.isDisabled = true;
        } else {
          itm.isDisabled = false;
        }
      });
    this.statementDateRangeValues.emit(val.value);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
